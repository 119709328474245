(() => {
    'use strict'

    let toggleOffcanvas = () => {
        document.querySelector('.offcanvas-collapse').classList.toggle('open')
        document.querySelector('.offcanvas-overlay').classList.toggle('open')
    }

    document.querySelector('#navbar-offcanvas').addEventListener('click', toggleOffcanvas)
    document.querySelector('.offcanvas-overlay').addEventListener('click', toggleOffcanvas)
})()
