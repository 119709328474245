(function () {
    var freeTrialFormTabs = $('.free-trial-form-tabs')
    var navLinkOnPrem = freeTrialFormTabs.find('.nav-link-on-prem')
    var navLinkSaas = freeTrialFormTabs.find('.nav-link-saas')

    var freeTrialForm = $('.free-trial-form');
    var serviceType = freeTrialForm.find('.service-type')

    console.log(navLinkOnPrem)

    var selectServiceTypeOnPrem = () => {
        serviceType.val('Standalone')
    }

    var selectServiceTypeSaas = () => {
        serviceType.val('Cloud')
    }

    navLinkOnPrem.on('click', selectServiceTypeOnPrem)
    navLinkSaas.on('click', selectServiceTypeSaas)
}())
